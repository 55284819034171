// Home.js
import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

function Home() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://aetherianchronicles.com:4000/api/submit', { email });
            if (response.data.success) {
                setMessage('Subscription successful!');
                setEmail(''); // 清空输入框
            } else {
                setMessage('Subscription failed. Please try again.');
            }
        } catch (error) {
            setMessage(`An error occurred: ${error.message || 'Unknown error'}`);
        }
    };

    return (
        <div className="App">
            <div className="header">
                <img src={`${process.env.PUBLIC_URL}/images/Logo.png`} alt="Logo" className="logo" />
            </div>
            <div className="content">
                <img src={`${process.env.PUBLIC_URL}/images/ContentText.png`} alt="Content Text" className="content-text" />
                <form className="row" onSubmit={handleSubmit}>
                    <div className="div1">
                        <input
                            type="text"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="div2">
                        <button type="submit"></button>
                    </div>
                </form>
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
}

export default Home;
